import React from 'react';

import Button from '../button/button';

import * as styles from './contact-form.module.scss';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function ContactForm({ source = '', className = '' }) {
  const initialState = {
    source,
    name: '',
    email: '',
    phone: '',
    enquiry: '',
  };
  const [state, setState] = React.useState(initialState);

  function handleChange(event) {
    setState((currentState) => ({
      ...currentState,
      [event.target.name]: event.target.value,
    }));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    console.log('Form submitted with values:', state);
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact-form',
          ...state,
        }),
      });
      alert('Thank you');
      setState(initialState);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  return (
    <form
      className={`${styles.form} ${className}`}
      name="contact-form"
      data-netlify="true"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="source" value={source} />
      <div className={styles.field}>
        <label htmlFor="name">Your name</label>
        <input
          type="text"
          name="name"
          id="name"
          required={true}
          value={state.name}
          onChange={handleChange}
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="email">Email address</label>
        <input
          type="email"
          name="email"
          id="email"
          required={true}
          value={state.email}
          onChange={handleChange}
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="phone">Telephone number</label>
        <input
          type="tel"
          name="phone"
          id="phone"
          required={true}
          value={state.phone}
          onChange={handleChange}
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="enquiry">Your enquiry</label>
        <textarea
          name="enquiry"
          id="enquiry"
          rows="3"
          required={true}
          value={state.enquiry}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="range-right">
        <Button type="submit">Send your enquiry</Button>
      </div>
    </form>
  );
}
