import * as React from 'react';
import { Link } from 'gatsby';

import Container from '../container/container';
import Social from '../social/social';

import * as styles from './footer.module.scss';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Container className={styles.footer__inner}>
        <div className={styles.footer__inner__links}>
          <Social />
          <div className={styles.legal}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-conditions">T&amp;Cs</Link>
          </div>
        </div>
        <div className={styles.footer__inner__smallprint}>
          <p>
            <small>
              &copy; Timeless Design and Build Ltd {new Date().getFullYear()}
            </small>
          </p>
          <p>
            <small>
              Registered office: Timeless Design and Build, 48 Marshalls Drive,
              St Albans, Herts AL14RQ
              <br />
              Registered in England and Wales. No. XXXXXXXXXXX
            </small>
          </p>
        </div>
      </Container>
    </footer>
  );
}
