import React from 'react';

import * as styles from './container.module.scss';

export default function Container({ className = '', children, id = null }) {
  return (
    <div id={id} className={`${styles.container} ${className}`}>
      {children}
    </div>
  );
}
