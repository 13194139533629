import React from 'react';

import * as styles from './social.module.scss';

export default function Social() {
  return (
    <ul className={styles.social}>
      <li>
        <a
          href="#twitter"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Find us on Twitter"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
              d="m20 40a20 20 0 1 1 20-20 20 20 0 0 1 -20 20"
              fill="#ab915b"
              fillRule="evenodd"
            />
            <path
              d="m31.49 12.7a9.15 9.15 0 0 1 -2.35.67c.38-.06.94-.75 1.16-1a4.46 4.46 0 0 0 .79-1.43.09.09 0 1 0 -.13-.11 12.84 12.84 0 0 1 -2.8 1.07.17.17 0 0 1 -.19-.06l-.25-.25a5.06 5.06 0 0 0 -1.35-.83 4.77 4.77 0 0 0 -2.08-.33 4.86 4.86 0 0 0 -2 .56 5 5 0 0 0 -1.59 1.3 4.8 4.8 0 0 0 -.95 1.9 5 5 0 0 0 -.05 2c0 .11 0 .12-.1.11a14.94 14.94 0 0 1 -9.6-4.9c-.11-.13-.17-.13-.27 0a4.88 4.88 0 0 0 .83 5.76c.19.17.39.35.59.51a4.8 4.8 0 0 1 -1.85-.51c-.11-.08-.17 0-.18.09a3.44 3.44 0 0 0 0 .55 4.94 4.94 0 0 0 3 3.93 3.19 3.19 0 0 0 .62.19 5.61 5.61 0 0 1 -1.74.08c-.14 0-.19 0-.14.16a5.15 5.15 0 0 0 3.83 3.21c.17 0 .34 0 .52.07-.43.65-1.92 1.14-2.61 1.38a9.41 9.41 0 0 1 -4 .51c-.21 0-.26 0-.31 0s0 .08.06.14c.27.18.54.34.82.49a13.45 13.45 0 0 0 2.66 1.04 14.12 14.12 0 0 0 13.7-3.2c2.81-2.79 3.8-6.64 3.8-10.49 0-.16.18-.24.28-.32a9.26 9.26 0 0 0 1.86-1.94.56.56 0 0 0 .11-.37c0-.06 0 0-.09 0"
              fill="#fff"
              fillRule="evenodd"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="#facebook"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Find us on Facebook"
        >
          <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="evenodd">
              <path
                d="m20 40a20 20 0 1 1 20-20 20 20 0 0 1 -20 20"
                fill="#ab915b"
              />
              <path
                d="m16.86 20.09v11.59a.29.29 0 0 0 .29.31h4.32a.29.29 0 0 0 .3-.28v-11.8h3.08a.3.3 0 0 0 .3-.28l.31-3.55a.32.32 0 0 0 -.29-.33h-3.4v-2.52a1 1 0 0 1 1-1h2.41a.32.32 0 0 0 .3-.3v-3.61a.3.3 0 0 0 -.26-.32h-4.11a4.33 4.33 0 0 0 -4.25 4.3v3.42h-2.15a.29.29 0 0 0 -.3.3v3.55a.29.29 0 0 0 .29.31h2.16z"
                fill="#fff"
              />
            </g>
          </svg>
        </a>
      </li>
      <li>
        <a
          href="#instagram"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Find us on Instagram"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <circle cx="20.14" cy="20.14" r="18.07" fill="#fff" />
            <path
              d="M30.33,24.84a5.21,5.21,0,0,1-5.21,5.21h-10a5.21,5.21,0,0,1-5.21-5.21v-10a5.2,5.2,0,0,1,5.21-5.2h10a5.2,5.2,0,0,1,5.21,5.2Zm2.38-10a7.59,7.59,0,0,0-7.59-7.58h-10a7.59,7.59,0,0,0-7.59,7.58v10a7.58,7.58,0,0,0,7.59,7.58h10a7.58,7.58,0,0,0,7.59-7.58ZM20.16,24a4,4,0,1,1,4-4,4,4,0,0,1-4,4Zm0-10.2A6.22,6.22,0,1,0,26.38,20a6.22,6.22,0,0,0-6.22-6.22Zm6.58-1.89a1.48,1.48,0,1,0,1.48,1.48,1.48,1.48,0,0,0-1.48-1.48ZM20,40A20,20,0,1,1,40,20,20,20,0,0,1,20,40Z"
              fill="#ab915b"
              fillRule="evenodd"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="#pinterest"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Find us on Pinterest"
        >
          <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m20 40a20 20 0 1 1 20-20 20 20 0 0 1 -20 20"
              fill="#ab915b"
            />
            <path
              d="m21.53 26.42c-1.43-.11-2-.82-3.16-1.5-.5 2.62-1.09 5.15-2.48 6.88a.71.71 0 0 1 -1.26-.39c-.21-4.18 1.23-7.5 1.94-11-1.34-2.27.16-6.83 3-5.7 3.49 1.38-3 8.42 1.35 9.3 4.57.92 6.43-7.93 3.6-10.81-4.1-4.15-11.92-.09-11 5.86.18 1.13 1.13 1.65 1 2.77a1 1 0 0 1 -1.42.8c-1.82-.85-2.38-2.71-2.29-5.07.16-4.51 4.05-7.67 8-8.11 4.94-.55 9.57 1.81 10.21 6.46.72 5.24-2.22 10.91-7.5 10.51"
              fill="#fff"
            />
          </svg>
        </a>
      </li>
    </ul>
  );
}
