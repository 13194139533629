import * as React from 'react';
import { Link } from 'gatsby';

import Container from '../container/container';
import Logo from '../logo/logo';

import * as styles from './header.module.scss';

export default function Header() {
  return (
    <header className={styles.header}>
      <Container className={styles.header__inner}>
        <Logo className={styles.header__logo} />
        <nav className={styles.header__menu}>
          <ul>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/properties">Our Properties</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              Call <a href="tel:+441234567890">01234 567890</a>
            </li>
          </ul>
        </nav>
      </Container>
    </header>
  );
}
