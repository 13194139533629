import { Link } from 'gatsby';
import React from 'react';

import * as styles from './button.module.scss';

export default function Button({ href, children, type = 'button' }) {
  if (href) {
    return (
      <Link to={href} className={styles.btn}>
        {children}
      </Link>
    );
  }

  return (
    <button type={type} className={styles.btn}>
      {children}
    </button>
  );
}
