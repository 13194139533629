import * as React from 'react';

import Container from '../container/container';
import Social from '../social/social';
import ContactForm from '../contact-form/contact-form';

import * as styles from './contact-section.module.scss';

export default function ContactSection() {
  return (
    <section className={styles.contact}>
      <Container>
        <div className={styles.contact__inner}>
          <h2 className="txt-primary">Contact Us</h2>
          <p>
            If you are interested in any of our properties or would like to
            discuss future projects or collaborations, we would love to hear
            from you. Please get in touch by phone, email or by completing this
            message form.
          </p>

          <ContactForm source="" className={styles.contact__form} />

          <p className={`${styles.contact__details} lg`}>
            Telephone: <a href="tel:+441234567890">01234 567890</a>
            <br />
            Email:{' '}
            <a href="mailto:info@timelessbs.co.uk">info@timelessbs.co.uk</a>
          </p>
          <div className={styles.contact__social}>
            <p>
              Follow us on social media to keep track with our latest
              developments and offers
            </p>
            <Social />
          </div>
        </div>
      </Container>
    </section>
  );
}
