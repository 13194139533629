import React from 'react';
import { Link } from 'gatsby';

import * as styles from './logo.module.scss';

export default function Logo({ className = '' }) {
  return (
    <Link
      to="/"
      aria-label="Timeless Homes"
      className={`${styles.logo} ${className}`}
    >
      <svg viewBox="0 0 680 420" xmlns="http://www.w3.org/2000/svg">
        <g fill="#ab915b" fillRule="evenodd">
          <path d="m0 184.25v7.46h33.5v125.05h7.45v-125.05h33.49v-7.46z" />
          <path d="m92.64 316.76h7.44v-132.5h-7.44z" />
          <path d="m135.07 184.25 49.71 86.13 49.63-86.13h8.53l-.34.58v131.93h-7.44v-119.01l-50.38 87.29-50.45-87.29v119.01h-7.44v-131.93l-.34-.58z" />
          <path d="m333.92 184.25v7.46h-58.72v55.07h58.72v7.45h-58.72v55.08h58.72v7.45h-66.17v-132.51z" />
          <path d="m356.67 316.76v-132.51h7.44v125.05h51.29v7.46z" />
          <path d="m501.42 184.25v7.46h-58.73v55.07h58.73v7.45h-58.73v55.08h58.73v7.45h-66.17v-132.51z" />
          <path d="m557.09 243.38q-14.15-4.71-22.34-12.92a28 28 0 0 1 39.71-39.58 23.48 23.48 0 0 1 6.28 9.85l-6.7 2.65a20.47 20.47 0 0 0 -4.88-7.45 20.63 20.63 0 0 0 -29.11 29.24q5.63 5.54 15.55 10.18a65.77 65.77 0 0 1 26.72 16 39.19 39.19 0 1 1 -66.43 34l7-2.57a31.92 31.92 0 1 0 54.26-26.33 60.24 60.24 0 0 0 -20.09-13.09" />
          <path d="m643.27 243.38q-14.14-4.71-22.33-12.92a28 28 0 0 1 39.7-39.58 23.5 23.5 0 0 1 6.29 9.85l-6.7 2.65a20.6 20.6 0 0 0 -4.88-7.45 20.63 20.63 0 0 0 -29.12 29.24q5.63 5.54 15.55 10.18a65.71 65.71 0 0 1 26.72 16 39.18 39.18 0 1 1 -66.42 34l7-2.57a31.92 31.92 0 1 0 54.26-26.33 60.39 60.39 0 0 0 -20.1-13.09" />
          <path d="m210.7 379.15h-29.68v-37.38h-6.14v77.33h6.14v-33.8h29.68v33.8h6.14v-77.33h-6.14z" />
          <path d="m272.26 340.68a39.64 39.64 0 1 0 28 11.62 38.3 38.3 0 0 0 -28-11.62m33.38 39.66a33.39 33.39 0 1 1 -9.77-23.63 32.43 32.43 0 0 1 9.77 23.63" />
          <path d="m361.79 389.86-27.78-48.09h-7.05l.84 1.42.06 75.92h6.13v-65.17l27.8 48.06 27.75-48.06v65.17h6.14v-75.81l.88-1.53h-7.05z" />
          <path d="m412.84 419.11h39.6v-6.15h-33.46v-29.45h33.46v-6.15h-33.46v-29.45h33.46v-6.14h-39.6z" />
          <path d="m503 380.24a38.22 38.22 0 0 0 -15.5-9.24 30.42 30.42 0 0 1 -8.58-5.62 10.79 10.79 0 0 1 15.24-15.38 10.78 10.78 0 0 1 2.56 3.89l.34.92 5.54-2.2-.27-.84a14.34 14.34 0 0 0 -3.81-6 16.51 16.51 0 0 0 -12-4.94 16.89 16.89 0 0 0 -12 28.83 33 33 0 0 0 13.06 7.57 33.66 33.66 0 0 1 11.11 7.23 16.43 16.43 0 0 1 5 12.15 17.23 17.23 0 0 1 -34.34 2.07l-.13-1.2-5.88 2.14.12.77a23.28 23.28 0 0 0 39.54 12.78 22.64 22.64 0 0 0 6.83-16.52 22.38 22.38 0 0 0 -6.83-16.41" />
          <path d="m349 50.4a62.32 62.32 0 0 1 3.46 20.35v33.15a62.11 62.11 0 0 1 -3.46 20.35 45.62 45.62 0 0 1 -4.81-20.35v-33.15a45.46 45.46 0 0 1 4.81-20.35zm-9 90.74a61.86 61.86 0 0 1 -4.84-7.43 54.72 54.72 0 0 0 4.84-9.07 53.27 53.27 0 0 0 4.84 9.07 63.1 63.1 0 0 1 -4.84 7.43zm-12.42-37.24v-33.15a62.06 62.06 0 0 1 3.47-20.35 45.59 45.59 0 0 1 4.81 20.35v33.15a45.62 45.62 0 0 1 -4.81 20.35 62.11 62.11 0 0 1 -3.47-20.35zm12.42-70.39a63 63 0 0 1 4.84 7.42 54.07 54.07 0 0 0 -4.84 9.07 54.07 54.07 0 0 0 -4.84-9.08 63 63 0 0 1 4.84-7.41zm83.08-25.22v-8.29h-33.08a70.38 70.38 0 0 0 -50 20.77 70.38 70.38 0 0 0 -50-20.77h-33.08v8.29h33.08a62.14 62.14 0 0 1 44.49 18.71 70.38 70.38 0 0 0 -4.86 7 53.86 53.86 0 0 0 -39.63-17.42h-33.08v8.29h33.08a45.73 45.73 0 0 1 35.5 16.9 69.32 69.32 0 0 0 -3.47 9.46 37.51 37.51 0 0 0 -32-18.08h-33.11v8.29h33.08a29.32 29.32 0 0 1 29.3 29.31v33.15a70.66 70.66 0 0 0 2.76 19.52 68.49 68.49 0 0 0 3.47 9.46 68.26 68.26 0 0 0 9 14.76 71 71 0 0 0 5.51 6.24 71 71 0 0 0 5.51-6.24 68.51 68.51 0 0 0 4.85-6.95 69.92 69.92 0 0 0 4.11-7.81 70.56 70.56 0 0 0 6.22-29v-33.13a29.32 29.32 0 0 1 29.27-29.31h33.11v-8.28h-33.11a37.51 37.51 0 0 0 -32 18.08 68 68 0 0 0 -3.47-9.46 45.73 45.73 0 0 1 35.47-16.91h33.11v-8.29h-33.11a53.89 53.89 0 0 0 -39.63 17.42 70.38 70.38 0 0 0 -4.86-7 62.16 62.16 0 0 1 44.49-18.71h33.11z" />
        </g>
      </svg>
    </Link>
  );
}
