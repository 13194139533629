import * as React from 'react';
import Helmet from 'react-helmet';

import Header from '../header/header';
import Footer from '../footer/footer';
import ContactSection from '../contact-section/contact-section';

import * as styles from './layout.module.scss';

export default function Layout({
  title,
  description,
  className = '',
  includeContactSection = true,
  children,
}) {
  return (
    <>
      <Helmet title={title} titleTemplate="%s | Timeless Homes">
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <link rel="canonical" href="https://timelesshomes.co.uk" />
      </Helmet>
      <Header />
      <main className={`${styles.content} ${className}`}>{children}</main>
      {includeContactSection && <ContactSection />}
      <Footer />
    </>
  );
}
